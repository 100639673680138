import React from "react"
{/*

structure...

<ImageContainer 
  container=""
  src=""
  alt=""
  className=""
/>
*/}

const ImageContainer = ({ container, src, alt, className }) => {
  let classImg =
    className && className.length !== 0
      ? className
      : "w-full object-cover h-auto"

  return (
    <div className={`${container}`}>
      <img src={`${src}`} alt={`${alt}`} className={`${classImg}`} />
    </div>
  )
}

export default ImageContainer