import React from "react"
import PropTypes from "prop-types"
import SEO from "./seo"
import Header from "./header"
import "./layout.css"

const Layout = ({ children, title, link }) => {
  return (
    <div>
      <SEO title={title} link={link} />
      <Header title={title} />
        {children}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
