import { Link } from "gatsby"
import React, { useState } from "react"
import Path from "../images/path.png"
import PropTypes from "prop-types"
import Icon from "../images/icon.png"
import MenuHam from "../images/menuHam.png"
import MenuX from "../images/menuX.png"
import ImageContainer from "./imageContainer"

const ListHeader = ({ title, link, text }) => {
  let show = title === text && text.length !== 0 ? "sm:block" : ""
  let selectMobile = title === text && text.length !==0 ? `bg-tertiary text-white ` : "text-tertiary"
  return (
    <Link
      to={link}
      className={`${selectMobile}  w-full py-2 sm:w-4/12 sm:ml-4 text-center plus-bold text-2xl sm:text-white sm:bg-transparent  notBgLink`}
    >
      {text}
      <ImageContainer container={`hidden ${show} mx-auto w-24`} src={Path} alt="Path"/>
    </Link>
  )
}

const HamburgerMobile = ({ Expanded }) => {
  if (Expanded === "hidden") {
    return <ImageContainer container="w-full" src={MenuHam} alt="MenuHam" />
  } else {
    return <ImageContainer container="w-full" src={MenuX} alt="MenuX" />
  }
}

const Header = ({ title }) => {
  const [expHeader, setExpHeader] = useState("hidden")
  return (
    <header className=" h-16 w-full absolute  z-40 ">
      <div className="container py-3 flex flex-wrap justify-between">
        <Link to="/">
          <ImageContainer container="w-16" src={Icon} />
        </Link>
        <div className={`sm:hidden`}>
          <button
            className={`flex items-center px-2 py-1 h-full text-white focus:outline-none`}
            onClick={() =>
              expHeader === "block"
                ? setExpHeader("hidden")
                : setExpHeader("block")
            }
          >
            <HamburgerMobile Expanded={expHeader} />
          </button>
        </div>
        <div
          className={`${expHeader} sm:block mt-2 xl:w-3/12 lg:w-4/12 md:w-5/12 sm:mt-0 sm:w-6/12 w-full `}
        >
          <div className="bg-white sm:bg-transparent rounded-lg flex flex-col sm:flex-row sm:justify-end my-auto overflow-hidden ">
            <ListHeader
              link="/"
              text="Home"
              title={title}
            />
            <ListHeader
              link="/contact-us"
              text="Contact"
              title={title}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
