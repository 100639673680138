import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import SocialImage from "../images/socialImage.jpg"

function SEO({ description, lang, link, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="description" content={metaDescription} />

      {/*<!-- Google / Search Engine Tags -->*/}
      <meta
        itemprop="name"
        content={site.siteMetadata.title}
      />
      <meta itemprop="description" content={metaDescription} />
      <meta
        itemprop="image"
        content={`${process.env.GATSBY_CORPORATION_SITE_URL}${SocialImage}`}
      />
      {/*<!-- Facebook Meta Tags -->*/}
      <meta
        property="og:url"
        content={`${process.env.GATSBY_CORPORATION_SITE_URL}`}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={site.siteMetadata.title}
      />
      <meta property="og:description" content={metaDescription} />
      <meta
        property="og:image"
        content={`${process.env.GATSBY_CORPORATION_SITE_URL}${SocialImage}`}
      />

      {/*<!-- Twitter Meta Tags -->*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={site.siteMetadata.title}
      />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:image"
        content={`${process.env.GATSBY_CORPORATION_SITE_URL}${SocialImage}`}
      />
      <link
        rel="canonical"
        href={`${process.env.GATSBY_CORPORATION_SITE_URL}${link}`}
      />
      <link href="https://fonts.googleapis.com/css2?family=Libre+Franklin&family=M+PLUS+Rounded+1c:wght@700&display=swap" rel="stylesheet" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  link: ``,
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default SEO
